import * as React from "react";
import styled from "@emotion/styled";
import {Button, Col, Form, Input, message, Row, Select, Space} from "antd";
import DarkButton from "../../../buttons/darkButton";
import {useState} from "react";
import jsonRequest from "../../../../utils/request/jsonRequest";

const Container = styled.div`
  padding: 0 24px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid rgba(0,0,0,0.06);
`

const AddSkillCard = (props) => {
  const {
    data = {},
    onUpdated = () => {},
    onCancel = () => {},
  } = props
  const [form] = Form.useForm()
  const [submitting, setSubmitting] = useState(false)

  const onFinish = (values) => {
    if (submitting) {
      return;
    }
    setSubmitting(true)
    jsonRequest(data.id ? `/account/skill/${data.id}` : "/account/skill", {
      method: data.id ? 'PUT' : 'POST',
      data: values
    }).then(rsp => {
      if (rsp.success) {
        message.success('保存成功')
        onUpdated()
      } else {
        message.error(rsp.message)
      }
    }).catch(console.log).finally(() => {
      setSubmitting(false)
    });
  }

  return (<>
    <Form {...{form, onFinish}} initialValues={data}>
      <Container>
        <Row gutter={24} style={{width: 'calc(100% - 150px)'}}>
          <Col span={12}>
            <Form.Item
              label='技能名称'
              name='name'
              labelCol={{span: 8}}
              required={false}
              rules={[{required: true, message: '请填写技能名称'}]}
              wrapperCol={{span: 16}}
              labelAlign='left'
              style={{margin: '24px 0'}}
            >
              <Input placeholder='熟悉的技能名称，如Java'/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='熟练程度'
              name='progress'
              required={false}
              rules={[{required: true, message: '请填写熟练程度'}]}
              labelCol={{span: 8}}
              wrapperCol={{span: 16}}
              labelAlign='left'
              style={{margin: '24px 0'}}
            >
              <Select
                placeholder='请选择'
                options={[
                  {
                    label: '10',
                    value: 10,
                  },
                  {
                    label: '20',
                    value: 20,
                  },
                  {
                    label: '30',
                    value: 30,
                  },
                  {
                    label: '40',
                    value: 40,
                  },
                  {
                    label: '50',
                    value: 50,
                  },
                  {
                    label: '60',
                    value: 60,
                  },
                  {
                    label: '70',
                    value: 70,
                  },
                  {
                    label: '80',
                    value: 80,
                  },
                  {
                    label: '90',
                    value: 90,
                  },
                  {
                    label: '100',
                    value: 100,
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Space size={8}>
          <Button onClick={onCancel}>取消</Button>
          <DarkButton size='middle' onClick={form.submit}>保存</DarkButton>
        </Space>
      </Container>
    </Form>
  </>)
}

export default AddSkillCard;
