import * as React from "react";
import {Button, message, Modal, Progress, Space} from "antd";
import OptionCard from "../../../cards/optionCard";
import {useState} from "react";
import AddSkillCard from "./addSkillCard";
import request from "../../../../utils/request";
import jsonRequest from "../../../../utils/request/jsonRequest";

const SkillCard = (props) => {
  const {
    data,
    onUpdated = () => {}
  } = props
  const [editing, setEditing] = useState(false)

  const handleDestroy = () => {
    Modal.confirm({
      title: '确定删除?',
      okText: '删除',
      cancelText: '取消',
      onOk: () => {
        return jsonRequest("/account/skill", {
          method: 'DELETE',
          data: {id: data.id}
        }).then((rsp) => {
          if (rsp.success) {
            message.success('删除成功')
            onUpdated()
          } else {
            message.error(rsp.message)
          }
        }).catch(console.error)
      }
    })
  }

  return !editing && (<>
    <OptionCard
      key={data.id}
      title={data.name}
      desc={
        <Progress style={{width: 460}} percent={data.progress} status='success' showInfo={false} />
      }
      options={(
        <Space size={8}>
          <Button onClick={() => {setEditing(true)}}>编辑</Button>
          <Button onClick={handleDestroy} danger>删除</Button>
        </Space>
      )}
    />
  </>) || (
    <AddSkillCard
      {...{data}}
      onUpdated={() => {
        setEditing(false)
        onUpdated()
      }}
      onCancel={() => setEditing(false)}
    />
  )
}

export default SkillCard;
