import * as React from "react";
import PersonalLayout from "../../../components/pages/personal/PersonalLayout";
import PersonalBody from "../../../components/pages/personal/PersonalBody";
import {message, Space} from "antd";
import PersonalAddCard from "../../../components/pages/personal/PersonalAddCard";
import AddSkillCard from "../../../components/pages/personal/resume/addSkillCard";
import {useEffect, useState} from "react";
import jsonRequest from "../../../utils/request/jsonRequest";
import auth from "../../../utils/auth";
import {useSelector} from "react-redux";
import SkillCard from "../../../components/pages/personal/resume/skillCard";
import Seo from "../../../components/seo";

const PersonalSkill = () => {
  auth.authorize()
  const {account} = useSelector((s: any) => s)
  const [adding, setAdding] = useState(false)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const load = () => {
    if (!account || loading) {
      return;
    }
    setLoading(true)
    jsonRequest("/account/skill").then(rsp => {
      if (rsp.success) {
        setData(rsp.data.data)
      } else {
        message.error(rsp.message)
      }
    }).catch(console.log).finally(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    load()
  }, [account])
  return (<>
    <PersonalLayout selected='resume' loading={loading}>
      <PersonalBody
        title='专业技能'
        backLink='/personal/resume'
      >
        <Space style={{width: '100%'}} direction='vertical' size={16}>
          {data.map(item => (
            <SkillCard key={item.id} data={item} onUpdated={load}/>
          ))}
          {adding && (
            <AddSkillCard
              onCancel={() => {
                setAdding(false)
              }}
              onUpdated={() => {
                load();
                setAdding(false)
              }}
            />
          )}
          {!adding && (
            <PersonalAddCard
              text='添加技能'
              onClick={() => {
                setAdding(true)
              }}
            />
          )}
        </Space>
      </PersonalBody>
    </PersonalLayout>
  </>)
}

export default PersonalSkill;

export const Head = () => <Seo />
